import appStrings from '@/app/utility/string.utility.js';
// import { required, minLength } from 'vuelidate/lib/validators';
import DatePicker from 'vue2-datepicker';
import taxType from '../../taxType';
import taxMaster from '../../taxMaster';
export default {
  name: 'accountMapping',
  components: { DatePicker, taxType, taxMaster },
  props: ['accountMappingDetails'],
  data() {
    return {
      loader: false,
      editMode: false,
      showAlert: false,
      isSuccess: false,
      responseMsg: '',
      showAlertCcid: false,
      isSuccessCcid: false,
      responseMsgCcid: '',
      showTaxTypeList: false,
      showTaxMasterList: false,
      showValueSetModal: false,
      showConcatSegmentModal: false,
      showPartyIndirectModel: false,
      parent_value_set_id: null,
      selectedIndex: null,
      accountIndex: null,
      concatIndex: null,
      taxType: null,
      entityType: null,
      entityValue: null,
      regNo: null,
      start_date: null,
      end_date: null,
      ccidMeaning: '',
      ccidCode: '',
      customerCcidList: [],
      customerCcidFields: [
        {
          key: 'segment_code'
        },
        {
          key: 'segment_meaning'
        },
        {
          key: 'ccid',
          class: 'd-none'
        }
      ],
      segmentData: [],
      segmentFields: [
        {
          key: 'segment_meaning'
        },
        {
          key: 'segment_code'
        }
      ],
      accountMappingData: [
        {
          tax_regime_mpng_id: 0,
          tax_regime_mpng_account_id: 0,
          le_name: null,
          le_id: null,
          lease_prj_name: null,
          lease_prj_id: null,
          lease_prj_code: null,
          tax_master_id: null,
          tax_name: null,
          tax_type: null,
          tax_type_id: null,
          regime_account_type_vset_code: null,
          regime_code_meaning: null,
          ccid: null,
          concatenated_segment: null,
          concatenated_segment_meaning: null,
          start_date: null,
          end_date: null
        }
      ],
      accountMappingFields: [
        {
          key: 'legal_entity'
        },
        // {
        //   key: 'project'
        // },
        {
          key: 'tax_master'
        },
        {
          key: 'tax_type'
        },
        {
          key: 'regime_type'
        },
        {
          key: 'concatenate_segment'
        },
        {
          key: 'start_date'
        },
        {
          key: 'end_date'
        },
        {
          key: 'add'
        },
        {
          key: 'remove'
        }
      ]
    };
  },
  // validations: {
  //   ccidMeaning: {
  //     required,
  //     minLength: minLength(3)
  //   },
  //   ccidCode: { required }
  // },
  mounted() {
    this.fillRecordFromAccountMapping();
    if (this.accountMappingDetails) {
      this.getAccountMappingDetails(
        this.accountMappingDetails.tax_regime_mpng_id
      );
    }
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'update') {
          this.editMode = true;
        }
        if (actionName === 'save') {
          this.addEditAccountMappingDetails();
        }
      }
    });
  },
  methods: {
    fillRecordFromAccountMapping() {
      this.taxType = this.accountMappingDetails.tax_type_value;
      this.entityType = this.accountMappingDetails.org_type;
      this.entityValue = this.accountMappingDetails.loc_name;
      this.regNo = this.accountMappingDetails.registration_no;
      this.start_date = this.accountMappingDetails.start_date;
      this.end_date = this.accountMappingDetails.end_date;
    },
    getAccountMappingDetails(taxRegimeMappingId) {
      // Get API for Account Mapping
      this.loader = true;
      this.$store
        .dispatch(
          'taxRegimeRegistration/getAccountMappingDetails',
          taxRegimeMappingId
        )
        .then(response => {
          this.loader = false;
          if (response.status === 200 && response.data.data.length) {
            this.accountMappingData = response.data.data;
            // this.totalRows = response.data.data.total_elements;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    addEditAccountMappingDetails() {
      // To Add Edit Account Mapping Details
      const accountDetails = this.accountMappingData.map(elem => {
        return {
          tax_master_id: elem.tax_master_id,
          regime_account_type_vset_code: elem.regime_account_type_vset_code,
          tax_type_id: elem.tax_type_id,
          cc_id: elem.ccid,
          start_date: elem.start_date,
          end_date: elem.end_date,
          tax_regime_mpng_account_id: elem.tax_regime_mpng_account_id
        };
      });
      const payload = {
        tax_regime_mpng_id: this.accountMappingDetails.tax_regime_mpng_id,
        tax_regime_mapping_details: accountDetails ? accountDetails : null
      };
      this.loader = true;
      this.$store
        .dispatch('taxRegimeRegistration/addEditAccountMappingDetails', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.editMode = false;
            this.showAlert = true;
            this.isSuccess = true;
            this.responseMsg = response.data.message;
          } else {
            this.showAlert = true;
            this.isSuccess = false;
            this.responseMsg = response.response.data.message;
          }
        })
        .catch(() => {
          this.loader = false;
          this.showAlert = true;
          this.isSuccess = false;
          this.responseMsg = appStrings.autoFailedMsg;
        });
    },
    //
    getCustomerCcidDetails() {
      // this.$v.$touch();
      // if (!this.$v.$invalid) {
      this.loader = true;
      const payload = {
        search: {
          le_id: this.accountMappingData[this.accountIndex].le_id,
          segment_code: this.ccidCode,
          segment_meaning: this.ccidMeaning
        }
      };
      this.$store
        .dispatch('party/getCustomerCcidDetils', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.customerCcidList = response.data.data;
          } else {
            this.showAlertCcid = true;
            this.isSuccessCcid = false;
            this.responseMsgCcid = response.response.data.message;
          }
        })
        .catch(error => {
          this.loader = false;
          alert(error.message);
        });
      // }
    },
    closePartyBankModel() {
      this.showPartyIndirectModel = false;
    },
    enableInputs(ccidIndex) {
      this.accountIndex = ccidIndex;
      this.showPartyIndirectModel = true;
    },
    selectedCcidRow(item) {
      this.accountMappingData[this.accountIndex].concatenated_segment =
        item.segment_code;
      this.accountMappingData[this.accountIndex].concatenated_segment_meaning =
        item.segment_meaning;
      this.accountMappingData[this.accountIndex].ccid = item.ccid;
      this.showPartyIndirectModel = false;
    },
    //
    selectedvalueSet(item) {
      // Value Selection for Value Set
      if (this.vsetCode === appStrings.VALUESETTYPE.LEGAL_ENTITY_LIST) {
        this.accountMappingData[this.selectedIndex].le_name = item.value_code;
        this.accountMappingData[this.selectedIndex].le_id =
          item.value_set_dtl_id;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.LEASE_PROJECT_LIST) {
        this.accountMappingData[this.selectedIndex].lease_prj_name =
          item.value_code;
        this.accountMappingData[this.selectedIndex].lease_prj_id =
          item.value_set_dtl_id;
        this.accountMappingData[this.selectedIndex].lease_prj_code =
          item.lease_prj_code;
      } else if (this.vsetCode === 'REGIME_ACCOUNT_TYPE') {
        this.accountMappingData[this.selectedIndex].regime_code_meaning =
          item.value_meaning;
        this.accountMappingData[
          this.selectedIndex
        ].regime_account_type_vset_code = item.value_code;
      }
    },
    openValueSetModal(vsetCode, index) {
      // Function for Open Value Set
      this.vsetCode = vsetCode;
      this.selectedIndex = index;
      this.showValueSetModal = true;
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    closeValueSetModal() {
      // To Close Value Set Modal
      this.showValueSetModal = false;
    },
    showHideTaxMasterModal(flag, index) {
      // To Open Modal for Tax Master
      this.taxMasterIndex = index;
      this.showTaxMasterList = flag;
    },
    selectedTaxName(item) {
      this.accountMappingData[this.taxMasterIndex].tax_name = item.tax_name;
      this.accountMappingData[this.taxMasterIndex].tax_master_id =
        item.tax_master_id;
      this.showTaxMasterList = false;
    },
    showHideTaxTypeModal(flag, index) {
      // To Open Modal for Tax Type
      this.taxTypeIndex = index;
      this.showTaxTypeList = flag;
    },
    selectTaxType(item) {
      this.accountMappingData[this.taxTypeIndex].tax_type = item.tax_type;
      this.accountMappingData[this.taxTypeIndex].tax_type_id = item.tax_type_id;
      this.showTaxTypeList = false;
    },
    // showHideConcatSegmentModal(flag, index) {
    //   this.concatIndex = index;
    //   if (!this.accountMappingData[this.selectedIndex].le_id) {
    //     return alert(appStrings.DEPENDENTVSETMSG.LEMSG);
    //   }
    //   if (!this.accountMappingData[this.selectedIndex].lease_prj_id) {
    //     return alert(appStrings.DEPENDENTVSETMSG.FMSPROJECTMSG);
    //   }
    //   if (flag) {
    //     this.getConcatSegmentList();
    //   } else {
    //     this.segmentData = [];
    //   }
    //   this.showConcatSegmentModal = flag;
    // },
    // getConcatSegmentList() {
    //   // To Get Concatenate Segment List
    //   const payload = {
    //     le_id: this.accountMappingData[this.concatIndex].le_id,
    //     segment_code: this.accountMappingData[this.concatIndex].lease_prj_code,
    //     segment_meaning: ''
    //   };
    //   this.$store
    //     .dispatch('leaseRecovery/getFlexFieldAccounting', payload)
    //     .then(response => {
    //       if (response.status === 200) {
    //         this.segmentData = response.data.data;
    //       }
    //     });
    // },
    // selectedSegment(item) {
    //   this.accountMappingData[this.concatIndex].ccid = item.ccid;
    //   this.accountMappingData[this.concatIndex].concatenated_segment =
    //     item.segment_code;
    //   this.accountMappingData[this.concatIndex].concatenated_segment_meaning =
    //     item.segment_meaning;

    //   this.showHideConcatSegmentModal(false);
    // },
    addNewRow() {
      if (this.editMode) {
        this.accountMappingData.push({
          tax_regime_mpng_id: 0,
          tax_regime_mpng_account_id: 0,
          le_name: null,
          le_id: null,
          lease_prj_name: null,
          lease_prj_id: null,
          lease_prj_code: null,
          tax_master_id: null,
          tax_name: null,
          tax_type: null,
          tax_type_id: null,
          regime_account_type_vset_code: null,
          regime_code_meaning: null,
          ccid: null,
          concatenated_segment: null,
          concatenated_segment_meaning: null,
          start_date: null,
          end_date: null
        });
      }
    },
    removeRow(index) {
      if (this.editMode) {
        this.accountMappingData.splice(index, 1);
      }
    }
  },
  beforeDestroy() {
    this.unsubscribe();
  }
};
