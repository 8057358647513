import DatePicker from 'vue2-datepicker';
import appStrings from '@/app/utility/string.utility';
export default {
  name: 'addTaxRegimeRegistration',
  components: {
    DatePicker
  },
  props: ['taxRegimeRegistrationDetails'],
  data() {
    return {
      showAlert: false,
      loader: false,
      isSuccess: false,
      responseMsg: '',
      editMode: false,
      showValueSetModal: false,
      tax_regime_id: null,
      taxType:{
        text: null,
        value:null
      } ,
      taxTypeValue: null,
      entityType: null,
      taxRegimeRegIndex: null,
      taxRegimeData: [
        {
          tax_regime_mpng_id: 0,
          entity_id: null,
          entity_value: null,
          registration_no: null,
          start_date: null,
          end_date: null
        }
      ],
      form: {
        taxType:{
          text: null,
          value:null
        },
        taxTypeValue: null,
        entityType: null,
        registration_no: null,
        start_date: null,
        end_date: null
      },
      taxRegimeFields: [
        {
          key: 'entity_value'
        },
        {
          key: 'registration_no'
        },
        {
          key: 'start_date'
        },
        {
          key: 'end_date'
        },
        {
          key: 'add'
        },
        {
          key: 'remove'
        }
      ]
    };
  },
  mounted() {
    if (this.taxRegimeRegistrationDetails) {
      this.fillRecordFromTaxRegistration();
    }
    // if (this.taxRegimeRegistrationDetails) {
    //   this.fillTaxRegimeRegistrationData(
    //     this.taxRegimeRegistrationDetails.tax_regime_id
    //   );
    // }
    else {
      this.editMode = true;
    }
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'save' && this.editMode) {
          this.addEditTaxRegimeRegistration();
        }
        if (actionName === 'update') {
          this.editMode = true;
        }
      }
    });
  },
  methods: {
    fillRecordFromTaxRegistration() {
      // Fill Records From Master Form
      this.form.tax_regime_mpng_id = this.taxRegimeRegistrationDetails.tax_regime_mpng_id;
      this.form.taxType.text = this.taxRegimeRegistrationDetails.tax_type_value;
      this.form.taxType.value=this.taxRegimeRegistrationDetails.tax_regime_id;
      this.form.taxTypeValue = this.taxRegimeRegistrationDetails.tax_type_group_value;
      this.form.entityType = this.taxRegimeRegistrationDetails.org_type;
      this.form.entity_value = this.taxRegimeRegistrationDetails.loc_name;
      this.form.registration_no = this.taxRegimeRegistrationDetails.registration_no;
      this.form.start_date = this.taxRegimeRegistrationDetails.start_date;
      this.form.end_date = this.taxRegimeRegistrationDetails.end_date;
    },
    fillTaxRegimeRegistrationData(tax_regime_id) {
      // Get by ID API for Tax Regime Registration
      this.$store
        .dispatch(
          'taxRegimeRegistration/fillTaxRegimeRegistrationData',
          tax_regime_id
        )
        .then(response => {
          if (response.data.data) {
            const result = response.data.data;
            if (result.document_sequence_category.length > 0) {
              this.data = result.document_sequence_category;
            }
            this.tax_regime_mpng_id = result.tax_regime_mpng_id;
            this.entity_id = result.entity_id;
            this.registration_no = result.registration_no;
            this.start_date = result.start_date;
            this.end_date = result.end_date;
            this.class_descrpition = result.class_descrpition;
            this.tax_regime_id = result.tax_regime_id;
            // this.category = result.category;
            // this.sub_cat_vset = result.sub_cat_vset;
            // this.sub_category = result.sub_category;
            // this.sub_sub_cat_vset = result.sub_sub_cat_vset;
            // this.sub_sub_category = result.sub_sub_category;
          }
        });
    },
    addEditTaxRegimeRegistration() {
      // Post API for Tax Regime Registration
      //   const filterDetails = this.taxRegimeData.filter(
      //     taxRegimeRegistrationDetail =>
      //       taxRegimeRegistrationDetail.seq_cat_name !== null
      //   );
      let details = [];
      if (!this.taxRegimeRegistrationDetails) {
        details = this.taxRegimeData.map(data => {
          return {
            tax_regime_mpng_id: data.tax_regime_mpng_id,
            entity_id: data.entity_id,
            registration_no: data.registration_no,
            start_date: data.start_date,
            end_date: data.end_date
          };
        });
      } else {
        details = [
          {
            tax_regime_mpng_id: this.form.tax_regime_mpng_id,
            entity_id: this.form.entity_id,
            registration_no: this.form.registration_no,
            start_date: this.form.start_date,
            end_date: this.form.end_date
          }
        ];
      }
      const payload = {
        tax_regime_id: this.form.taxType.value,
        tax_regime_details: details ? details : null
      };
      this.$store
        .dispatch('taxRegimeRegistration/addEditTaxRegimeRegistration', payload)
        .then(response => {
          this.$emit('updateTaxRegimeRegistrationList');
          this.showAlert = true;
          if (response.status === 200) {
            this.isSuccess = true;
            this.responseMsg = response.data.message;
            this.editMode = false;
          } else {
            this.isSuccess = false;
            this.responseMsg = response.response.data.message;
          }
        })
        .catch(err => {
          this.showAlert = true;
          this.loader = false;
          this.isSuccess = false;
          this.responseMsg = appStrings.autoFailedMsg;
          this.error = err;
        });
    },
    validateEntity(moduleName, index) {
      // Validate Entity Value
      this.selectedIndex = index;
      if (moduleName === 'LOC') {
        this.openValueSetModal(appStrings.VALUESETTYPE.LOCATION);
      }
    },
    openValueSetModal(vsetCode) {
      this.vsetCode = vsetCode;
      this.showValueSetModal = true;
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    closeValueSetModal() {
      this.showValueSetModal = false;
    },
    selectedvalueSet(item) {
      if (this.vsetCode === 'TAX_TYPE') {
        this.form.taxType.text = item.tax_type_value;
        this.form.taxType.value = item.tax_regime_id;
        this.form.taxTypeValue = item.tax_type_group_value;
        this.form.entityType = item.org_type_lookup;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.LOCATION) {
        this.taxRegimeData[this.selectedIndex].entity_value = item.value_code;
        this.taxRegimeData[this.selectedIndex].entity_id =
          item.value_set_dtl_id;
      }
    },
    addNewRow() {
      if (this.editMode) {
        this.taxRegimeData.push({
          tax_regime_mpng_id: 0,
          entity_id: null,
          entity_value: null,
          registration_no: null,
          start_date: null,
          end_date: null
        });
      }
    },
    removeRow(index) {
      this.taxRegimeData.splice(index, 1);
    },
    clearVsetValues(vsetCode, index) {
      if (vsetCode === 'TAX_TYPE') {
        this.form.taxType.text = null;
        this.form.taxType.value =null;
        this.form.taxTypeValue = null;
        this.form.entityType = null;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.LOCATION) {
        this.taxRegimeData[index].entity_value = null;
        this.taxRegimeData[index].entity_id = null;
      }
    }
  },
  beforeDestroy() {
    this.unsubscribe();
    clearTimeout(this.setTimeVsetCode);
  }
};
