import commonHelper from '@/app/utility/common.helper.utility';
import appStrings from '@/app/utility/string.utility';
import addTaxRegimeRegistration from './addTaxRegimeRegistration';
import accountMapping from '../taxRegimeRegistration/accountMapping';
export default {
  name: 'taxRegimeRegistration',
  watch: {
    currentPage: function() {
      this.getTaxRegimeRegistration();
    },
    perPage: function() {
      this.currentPage = 1;
      this.getTaxRegimeRegistration();
    }
  },
  components: {
    addTaxRegimeRegistration,
    accountMapping
  },
  data() {
    return {
      unsubscribe: null,
      showAccountModal: false,
      perPage: commonHelper.perPageRecord,
      pageOptions: commonHelper.getPageOption(),
      currentPage: 1,
      payload: {},
      showValueSetModal: false,
      accountMappingDetails: null,
      showTaxRegimeRegistrationModal: false,
      totalRows: null,
      loader: false,
      taxType: null,
      taxTypeId: null,
      taxRegimeRegistrationDetails: null,
      entityValue: null,
      entityId: null,
      registrationNum: null,
      taxRegimeRegistrationData: [],
      taxRegimeRegistrationFields: [
        {
          key: 'tax_type_value',
          label: 'Tax Type'
        },
        {
          key: 'org_type',
          label: 'Entity Type'
        },
        {
          key: 'loc_name',
          label: 'Entity Value'
        },
        {
          key: 'registration_no.'
        },
        {
          key: 'start_date'
        },
        {
          key: 'end_date'
        },
        {
          key: 'account_mapping'
        }
      ]
    };
  },
  mounted() {
    this.getTaxRegimeRegistration();
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'add') {
          this.hideTaxRegimeRegistrationModal(true);
          this.taxRegimeRegistrationDetails = null;
        }
        if (actionName === 'download' && !this.showTaxRegimeRegistrationModal) {
          this.loader = true;
          /**
           * @param(payload, 'action name', 'file name')
           */
          const downloadpayload = { ...this.payload };
          downloadpayload._limit = this.totalRows;
          this.downloadExcel.downloadData(
            downloadpayload,
            'taxRegimeRegistration/getTaxRegimeRegistration',
            'taxRegime-registration',
            () => (this.loader = false)
          );
        }
      }
    });
  },
  methods: {
    getTaxRegimeRegistration() {
      // get grid tax regime details
      this.payload = {
        _page: this.currentPage - 1,
        _limit: this.perPage,
        tax_type: this.taxType,
        entity_value: this.entityValue,
        registration_no: this.registrationNum
      };
      this.loader = true;
      this.$store
        .dispatch(
          'taxRegimeRegistration/getTaxRegimeRegistration',
          this.payload
        )
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.taxRegimeRegistrationData = response.data.data.page;
            this.taxRegimeRegistrationData.map(data => {
              data.account_mapping = 'Details';
              return data;
            });
            this.totalRows = response.data.data.total_elements;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    updateTaxRegimeRegistrationList() {
      this.getTaxRegimeRegistration();
    },
    hideTaxRegimeRegistrationModal(flag) {
      this.showTaxRegimeRegistrationModal = flag;
    },
    rowSelected(rowData) {
      this.hideTaxRegimeRegistrationModal(true);
      this.taxRegimeRegistrationDetails = rowData;
    },
    clear() {
      this.taxType = null;
      this.entityValue = null;
      this.registrationNum = null;
      this.getTaxRegimeRegistration();
    },
    openAccountModal(flag, item) {
      this.accountMappingDetails = item;
      this.showAccountModal = flag;
    },
    openValueSetModal(vsetCode) {
      this.vsetCode = vsetCode;
      this.showValueSetModal = true;
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    closeValueSetModal() {
      this.showValueSetModal = false;
    },
    selectedvalueSet(item) {
      if (this.vsetCode === 'TAX_TYPE') {
        this.taxType = item.tax_type_value;
        this.taxTypeId = item.value_set_dtl_id;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.LOCATION) {
        this.entityValue = item.value_code;
        this.entityId = item.value_set_dtl_id;
      }
    },
    clearVsetValues(vsetCode) {
      if (vsetCode === 'TAX_TYPE') {
        this.taxType = null;
        this.taxTypeId = null;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.LOCATION) {
        this.entityValue = null;
        this.entityId = null;
      }
    }
  },
  beforeDestroy() {
    this.unsubscribe();
    clearTimeout(this.setTimeVsetCode);
  }
};
